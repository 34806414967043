
import {
  Component,
  Vue
} from 'vue-property-decorator'
import Status from '@/components/Status/index.vue'
import DateHelper from '@/mixins/date-mixins'
import LocationService from '../../../services/location-service'
import DeliveryService from '../../../services/delivery-service'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  mask
} from 'vue-the-mask'
  @Component({
    name: 'DeliveryDispatch',
    components: {
      Status
    },
    directives: {
      mask
    }
  })
export default class DeliveryDispatch extends Vue {
    protected isTableBusy = false
    protected isLoader = false
    protected locations: any = []
    protected location: any = null
    protected notes: any = []
    protected note: any = {}

    protected fields: any = [{
      key: 'collapse',
      label: '',
      thStyle: {
        width: '2%'
      },
      class: 'text-center'
    },
    {
      key: 'vehicle_number',
      label: 'Vehicle No.',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'is_vessel_dispatch',
      label: 'Vessel',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'status',
      label: 'Status',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'location_name',
      label: 'Location',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vehicle_capacity',
      label: 'Capacity',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'dispute',
      label: 'Dispute',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'remark',
      label: 'Remark',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'arrival_date',
      label: 'Arrival',
      thStyle: {
        width: '12%'
      }
    },
    {
      key: 'action',
      label: 'Approve',
      thStyle: {
        width: '8%'
      },
      class: 'text-center'
    }
    ]

    get noOfBowsers () {
      return this.notes.length === 0 ? null : this.notes.length
    }

    get dispatchQty () {
      let amount = 0
      return this.notes.reduce((total, note) => {
        amount = (total + parseFloat(note.total_tonnage === null ? 0 : note.total_tonnage))
        return amount
      }, null)
    }

    created () {
      this.boot()
    }

    mounted () {
      this.notes = this.notes.map(notes => ({
        ...notes,
        isEdit: false
      }))
    }

    protected boot () {
      this.populateYards()
      this.populateDispatch()
    }

    protected populateDispatch () {
      this.location = null
      this.notes = []
      this.isTableBusy = true
      DeliveryService.getDispatch().then((response) => {
        this.isTableBusy = false

        response.data.forEach((note) => {
          if (note.status === 'dispatched') {
            this.notes.push({
              id: note.id,
              dispatch_id: note.dispatch_id,
              vehicle_id: note.vehicle_id,
              vehicle_number: note.vehicle_number,
              vehicle_capacity: note.vehicle_capacity,
              dispatch_note_number: note.dispatch_note_number,
              status: note.status,
              trip_number: note.trip_number,
              driver: note.driver,
              completed_loading: note.completed_loading,
              commence_loading: note.commence_loading,
              item_id: note.item_id,
              location_id: note.location_id,
              location: note.location_name,
              content_type: note.content_type,
              lines: note.lines,
              total_tonnage: note.total_tonnage === null ? 0 : note.total_tonnage,
              is_vessel_dispatch: note.is_vessel_dispatch === 1,
              is_send_to_mepa: note.is_send_to_mepa === 1,
              is_mepa_verified: note.is_mepa_verified === 1,
              is_send_to_customs: note.is_send_to_customs === 1,
              is_custom_verified: note.is_custom_verified === 1,
              is_gate_out: note.is_gate_out === 1,
              arrival_date: note.arrival_date === null ? DateHelper.today('dd/mm/yyyy') : note
                .arrival_date,
              is_seal_mismatch: note.is_seal_mismatch === 1,
              is_quantity_mismatch: note.is_quantity_mismatch === 1,
              remark: note.remark === null ? null : note.remark,
              approval_remark: note.approval_remark === null ? null : note.approval_remark,
              seal_up: note.seal_up,
              seal_down: note.seal_down,
              created_date: note.created_date
            })
          }
        })
      })
    }

    protected approveDelivery (note: any) {
      if (note.is_seal_mismatch || note.is_quantity_mismatch) {
        Object.assign(this.note, note);
        (this.$refs.approvalModal as any).show()
      } else this.approve(note)
    }

    protected approveRemark () {
      this.note.is_quantity_mismatch = false
      this.note.is_seal_mismatch = false
      this.approve(this.note)
    }

    protected approve (note: any) {
      this.isTableBusy = true
      this.isLoader = true
      const data: any = {
        dispatch_note_id: note.id,
        item_id: note.item_id,
        location_id: note.location_id,
        total_tonnage: note.total_tonnage,
        arrival_date: note.arrival_date,
        is_seal_mismatch: note.is_seal_mismatch,
        is_quantity_mismatch: note.is_quantity_mismatch,
        remark: note.remark,
        approval_remark: note.approval_remark === null ? null : note.approval_remark,
        modified_user: AuthModule.user.fullname
      }

      DeliveryService.approve(data).then((response) => {
        this.isLoader = false
        this.isTableBusy = false
        ToastModule.message(response.data.message)
        this.populateDispatch()
        this.note = {};
        (this.$refs.approvalModal as any).hide()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected populateYards () {
      LocationService.getYards().then((response) => {
        response.data.forEach(item => {
          this.locations.push({
            value: item.name,
            text: item.name
          })
        })
      })
    }
}

