import APIService from '@/services/api-service'
const resource = '/dispatch'

class ReceivableService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getApprovedDispatchedBowsers () {
    return this.connector.get(`${resource}/delivery/approved-dispatched-bowsers`)
  }

  public getDispatch () {
    return this.connector.get(`${resource}/delivery/notes`)
  }

  public getNoteDetails (noteId) {
    return this.connector.get(`${resource}/delivery/note-details/${noteId}`)
  }

  public getNotesByLocation (location: any) {
    return this.connector.get(`${resource}/delivery/location/${location}`)
  }

  public approve (data: any) {
    return this.connector.post(`${resource}/delivery/approve`, { data: data })
  }
}

export default new ReceivableService()
